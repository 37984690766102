import { Route, Redirect } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const { addToast } = useToasts();

  return (
    <Route
      render={({ location, ...props }) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        }
        addToast("You are not logged in. Please log in first!", {
          appearance: "info",
        });
        return <Redirect to={{ pathname: "/", state: { from: location } }} />;
      }}
      {...rest}
    />
  );
};

export default PrivateRoute;

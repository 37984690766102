import { QueryClient, QueryClientProvider } from "react-query";
import { ToastProvider } from "react-toast-notifications";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import "App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import React from 'react'
import Routes from "Routes";

const queryClient = new QueryClient();

function App() {
  const history = useHistory();

  

  return (
    <Router history={history} basename="/">
      <QueryClientProvider client={queryClient}>
        <ToastProvider
          autoDismissTimeout={5000}
          placement="top-left"
          autoDismiss
        >
          <Router>
            <Routes />
          </Router>
        </ToastProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Router>
  );
}

export default App;

import { reactLocalStorage } from "reactjs-localstorage";
import jwtDecode from "jwt-decode";

const useAuthUser = () => {
  let decoded = reactLocalStorage.get("pppAccessToken")
    ? jwtDecode(reactLocalStorage.get("pppAccessToken")) 
    : null;
  // //console.log(decoded?.sub);
  return {
    accessToken: reactLocalStorage.get("pppAccessToken", ""),
    refreshToken: reactLocalStorage.get("pppRefreshToken", ""),
    isAuthenticated: reactLocalStorage.get("pppIsLoggedIn", false),
    userId: decoded?.sub,
    userRole: decoded?.role,
  };
};

export default useAuthUser;

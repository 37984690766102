import React from "react";
import { SpinnerCircularFixed } from "spinners-react";
import tw from "tailwind-styled-components/dist/tailwind";

//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

//!STYLES

const AkFallback = tw.div`
    w-full 
    h-full 
    absolute z-20
    flex flex-col justify-center items-center 
    bg-gradient-to-b from-green-300 via-green-100 to-green-50
`;

//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

//!MAIN

const Fallback = () => {
  return (
    <AkFallback>
      <SpinnerCircularFixed
        size={90}
        thickness={100}
        speed={100}
        color="#451F52"
        secondaryColor="rgba(0, 0, 0, 0.44)"
      />
    </AkFallback>
  );
};

//|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

//!EXPORT

export default Fallback;

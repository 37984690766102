import React, { Suspense, lazy } from "react";
import { Switch, useLocation } from "react-router-dom";
import Fallback from "./Fallback";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import useAuthUser from "api/UserResource/useAuthUser";

const Login = lazy(() => import("pages/Login/Login"));
const Register = lazy(() => import("pages/Register/Register"));
const Application = lazy(() => import("pages/Application/Application"));

const AdminSpecialties = lazy(() =>
  import("pages/AdminSpecialties/AdminSpecialties")
);
const Test = lazy(() => import("pages/Test/Test"));
const AdminPayroll = lazy(() => import("pages/AdminPayroll/AdminPayroll"));
const HandbookDoc = lazy(() => import("pages/Contract/HandbookDoc"));
const Nextofkin = lazy(() => import("pages/Nextofkin/Nextofkin"));
const WelcomeOnboard = lazy(() => import("pages/Contract/WelcomeOnboard"));
const Contract = lazy(() => import("pages/Contract/Contract"));
const Welcome = lazy(() => import("pages/Welcome/Welcome"));
const Trainings = lazy(() => import("pages/Trainings/Trainings"));
const Assessment = lazy(() => import("pages/Assessment/Assessment"));
const MandatoryAssessment = lazy(() => import("pages/MandatoryAssessment/MandatoryAssessment"));
const Skillset = lazy(() => import("pages/Skillset/Skillset"));
const OccupationalHealthCheck =lazy(()=> import("pages/HealthChecks/HealthCheck"));
const Documents = lazy(() => import("pages/Documents/Documents"));
const Reference = lazy(() => import("pages/Reference/Reference"));
const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const Vacancies = lazy(() => import("pages/Vacancies/Vacancies"));
const Bookings = lazy(() => import("pages/Bookings/Bookings"));
// const Availability = lazy(() => import("pages/Availability/Availability"));
const Timesheet = lazy(() => import("pages/Timesheet/Timesheet"));
const Profile = lazy(() => import("pages/Profile/Profile"));
const Admin = lazy(() => import("pages/Admin/Admin"));
const Clients = lazy(() => import("pages/Clients/Clients"));
const BusinessUnits = lazy(() => import("pages/BusinessUnits/BusinessUnits"));
const RatesRules = lazy(() => import("pages/RatesRules/RatesRules"));
const CandidateList = lazy(() => import("pages/CandidateList/CandidateList"));
const CandidateAva = lazy(() => import("pages/CandidateAva/CandidateAva"));
const RegistrationSignOff = lazy(() => import("pages/Contract/RegistrationSignOff"));
const KeyInformationDocument = lazy(() => import("pages/Contract/KeyInformationDocument"));
const RightToAuditDeclaration = lazy(() => import("pages/Contract/RightToAuditDeclaration"));


const AdminVacancies = lazy(() =>
  import("pages/AdminVacancies/AdminVacancies")
);
const AdminBookings = lazy(() => import("pages/AdminBookings/AdminBookings"));

const WeeklyTimesheets = lazy(() =>
  import("pages/WeeklyTimesheets/WeeklyTimesheets")
);
const SignedTimesheets = lazy(() =>
  import("pages/SignedTimesheets/SignedTimesheets")
);
const ClientInvoices = lazy(() =>
  import("pages/ClientInvoices/ClientInvoices")
);
const CandidateInvoices = lazy(() =>
  import("pages/CandidateInvoices/CandidateInvoices")
);
const Reports = lazy(() => import("pages/Reports/Reports"));
const InactiveCandidates = lazy(() =>
  import("pages/InactiveCandidates/InactiveCandidates")
);
const ClientDash = lazy(() => import("pages/ClientDash/ClientDash"));
const Specialties = lazy(() => import("pages/Specialties/Specialties"));
const ClientVacancies = lazy(() =>
  import("pages/ClientVacancies/ClientVacancies")
);
const ClientCandidates = lazy(() =>
  import("pages/ClientCandidates/ClientCandidates")
);
const CandidateRatings = lazy(() =>
  import("pages/CandidateRatings/CandidateRatings")
);
const CandidateBooking = lazy(() =>
  import("pages/CandidateBooking/CandidateBooking")
);

const Notifications = lazy(() => import("pages/CandidateNotification/NotificationList"));

const Logout = lazy(() =>
  import("components/Logout/index")
);
const ClientInvoice = lazy(() => import("pages/ClientInvoice/ClientInvoice"));
const ForgotPassword = lazy(() =>
  import("pages/ForgotPassword/ForgotPassword")
);
const Finance = lazy(() => import("pages/Finance/Finance"));

const Notification = lazy(() => import("pages/Notification/NotificationList"));



const Routes = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthUser();

  const routes = [
    {
      path: "/",
      secure: false,
      component: Login,
    },
    {
      path: "/register",
      secure: false,
      component: Register,
    },
    {
      path: "/adminpayroll",
      secure: true,
      component: AdminPayroll,
    },
    
    {
      path: "/test",
      secure: false,
      component: Test,
    },
    {
      path: "/welcome",
      secure: false,
      component: Welcome,
    },
    {
      path: "/application",
      secure: true,
      component: Application,
    },

    {
      path: "/welcomeonboard",
      secure: true,
      component: WelcomeOnboard,
    },

    {
      path: "/index",
      secure: true,
      component: Logout,
    },

    {
      path: "/handbookdoc",
      secure: true,
      component: HandbookDoc,
    },
    {
      path: "/nextofkin",
      secure: true,
      component: Nextofkin,
    },
    {
      path: "/contract",
      secure: true,
      component: Contract,
    },
    {
      path: "/keyInformationDocument",
      secure: true,
      component: KeyInformationDocument,
    },
    {
      path: "/rightToAuditDeclaration",
      secure: true,
      component: RightToAuditDeclaration,
    },
    {
      path: "/registrationSignOff",
      secure: true,
      component: RegistrationSignOff,
    },
    {
      path: "/trainings",
      secure: true,
      component: Trainings,
    },
    {
      path: "/assessment",
      secure: true,
      component: Assessment,
    },
    {
      path: "/mandatory-assessment",
      secure: true,
      component: MandatoryAssessment,
    },
    {
      path: "/skillset",
      secure: true,
      component: Skillset,
    },
    {
      path: "/occupational-health-check",
      secure: true,
      component: OccupationalHealthCheck,
    },
    {
      path: "/documents",
      secure: true,
      component: Documents,
    },
    {
      path: "/reference",
      secure: true,
      component: Reference,
    },
    {
      path: "/dashboard",
      secure: true,
      component: Dashboard,
    },
    {
      path: "/vacancies",
      secure: true,
      component: Vacancies,
    },
    {
      path: "/bookings",
      secure: false,
      component: Bookings,
    },
    // {
    //   path: "/availability",
    //   secure: true,
    //   component: Availability,
    // },
    {
      path: "/timesheet",
      secure: true,
      component: Timesheet,
    },
    {
      path: "/profile",
      secure: true,
      component: Profile,
    },
    {
      path: "/admin",
      secure: true,
      component: Admin,
    },
    {
      path: "/clients",
      secure: true,
      component: Clients,
    },
    {
      path: "/businessUnits",
      secure: true,
      component: BusinessUnits,
    },
    {
      path: "/ratesRules",
      secure: true,
      component: RatesRules,
    },
    {
      path: "/candidateList",
      secure: true,
      component: CandidateList,
    },
    {
      path: "/candidateAva",
      secure: true,
      component: CandidateAva,
    },
    {
      path: "/adminVacancies",
      secure: true,
      component: AdminVacancies,
    },
    {
      path: "/adminBookings",
      secure: true,
      component: AdminBookings,
    },
    {
      path: "/weeklyTimesheets",
      secure: true,
      component: WeeklyTimesheets,
    },
    {
      path: "/signedTimesheets",
      secure: true,
      component: SignedTimesheets,
    },
    {
      path: "/clientInvoices",
      secure: true,
      component: ClientInvoices,
    },
    {
      path: "/candidateInvoices",
      secure: true,
      component: CandidateInvoices,
    },
    {
      path: "/candidateNotification",
      secure: true,
      component: Notifications,
    },
    {
      path: "/reports",
      secure: true,
      component: Reports,
    },
    {
      path: "/inactiveCandidates",
      secure: true,
      component: InactiveCandidates,
    },
    {
      path: "/clientDash",
      secure: true,
      component: ClientDash,
    },
    {
      path: "/specialties",
      secure: true,
      component: Specialties,
    },
    {
      path: "/clientVacancies",
      secure: true,
      component: ClientVacancies,
    },
    {
      path: "/clientCandidates",
      secure: true,
      component: ClientCandidates,
    },
    {
      path: "/adminSpecialties",
      secure: false,
      component: AdminSpecialties,
    },
    {
      path: "/candidateRatings",
      secure: true,
      component: CandidateRatings,
    },
    {
      path: "/candidateBooking",
      secure: true,
      component: CandidateBooking,
    },
    {
      path: "/clientInvoice",
      secure: true,
      component: ClientInvoice,
    },
    {
      path: "/forgot-password",
      secure: false,
      component: ForgotPassword,
    },
    {
      path: "/finance",
      secure: true,
      component: Finance,
    },
    {
      path: "/notification",
      secure: true,
      component: Notification,
    },
  ];

  return (
    <Suspense fallback={<Fallback />}>
      <Switch location={location} key={location.key}>
        {routes.map(({ path, secure, component }, index) => {
          return secure ? (
            <PrivateRoute
              exact
              path={path}
              isAuthenticated={isAuthenticated}
              key={index}
              component={component}
            />
          ) : (
            <PublicRoute
              exact
              path={path}
              isAuthenticated={isAuthenticated}
              key={index}
              component={component}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Routes;
